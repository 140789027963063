import { default as indexKF1U6OMiNtMeta } from "/app/client/pages/form/index.vue?macro=true";
import { default as indexKEfWT7YbtFMeta } from "/app/client/pages/fuelindex/index.vue?macro=true";
import { default as indexcBBbuxes8TMeta } from "/app/client/pages/index.vue?macro=true";
import { default as _91slug_93VdsZtFP7l8Meta } from "/app/client/pages/services/[slug].vue?macro=true";
import { default as indexks2R8MggliMeta } from "/app/client/pages/services/index.vue?macro=true";
export default [
  {
    name: "form",
    path: "/form",
    component: () => import("/app/client/pages/form/index.vue").then(m => m.default || m)
  },
  {
    name: "fuelindex",
    path: "/fuelindex",
    component: () => import("/app/client/pages/fuelindex/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "services-slug",
    path: "/services/:slug()",
    component: () => import("/app/client/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/app/client/pages/services/index.vue").then(m => m.default || m)
  }
]