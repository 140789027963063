<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
const route = useRoute()

useHead(() => ({
  htmlAttrs: {
    lang: 'ru'
  },
  meta: [
    { charset: 'utf-8' },
    {
      name: 'msapplication-TileColor',
      content: '#00aba9'
    },
    {
      name: 'theme-color',
      content: '#ffffff'
    },
  ],
  link: [
    {
      rel: 'canonical',
      href: `https://www.petrolplus.ru${route.path}`
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.ico'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/favicons/apple-touch-icon.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicons/favicon-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicons/favicon-16x16.png'
    },
    {
      rel: 'manifest',
      href: '/favicons/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      href: '/favicons/safari-pinned-tab.svg',
      color: '#5bbad5',
    },
  ],
}))
</script>

<style lang="scss">
// layout transition
.layout-enter-active {
  transition: all 0.1s ease-out;
}

.layout-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.layout-enter-from,
.layout-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
