import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_1doCGnr5OF from "/app/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import plugin_client_W86eSGFqyZ from "/app/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import gtm_client_GQxZ0Sjbav from "/app/node_modules/@petrolplus/common/client/plugins/gtm.client.ts";
import sentry_client_2LVslgmuJG from "/app/node_modules/@petrolplus/common/client/plugins/sentry.client.ts";
import swiper_WpO3DEbGeM from "/app/node_modules/@petrolplus/common/client/plugins/swiper.ts";
import ui_kit_lWtJrpWWlo from "/app/node_modules/@petrolplus/common/client/plugins/ui-kit.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_1doCGnr5OF,
  plugin_client_W86eSGFqyZ,
  gtm_client_GQxZ0Sjbav,
  sentry_client_2LVslgmuJG,
  swiper_WpO3DEbGeM,
  ui_kit_lWtJrpWWlo
]